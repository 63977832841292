import { useEffect, useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Fade from "@mui/material/Fade";
import "./Accordion.css";
import useAuth from "../../hooks/useAuth";
import {
  Button,
  Skeleton,
  Stack,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import VLineStepper from "../stepper/VLineStepper";
import TransactionFlexibleTable from "../../features/members/memberTables/BasicTable";

const AccordionTransition = ({
  data,
  paymentclick,
  isLoading,
  handleDialogOpen,
  computationData,
  type,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const dataInfo = computationData;

  console.log(dataInfo);

  const [expanded, setExpanded] = useState(false);
  const [loanInfo, setLoanInfo] = useState({});

  const handleExpansion = () => {
    setExpanded((prevExpanded) => !prevExpanded);
  };

  let amountLoan = parseFloat(dataInfo.loanAmount);
  let interest_rate = parseFloat(dataInfo.loanInterest);
  let termLoan = parseInt(dataInfo.loanTerm);
  let loan_status =
    dataInfo.loan_status === 0
      ? "#ffb300"
      : dataInfo.loan_status === 1
      ? "#b71c1c"
      : dataInfo.loan_status === 2
      ? "#4caf50"
      : "#929495";
  useEffect(() => {
    const loanInfo = {
      effectived_date: dataInfo.effectived_date,
      loanId: dataInfo.loanId,
      paidTerms: dataInfo.paidTerms,
      paidAmount: dataInfo.paidAmount,
      loanAmount: amountLoan,
      loanTerm: termLoan,
      loanInterest: interest_rate,
      finalAmount: dataInfo.finalAmount,
      monthlyPayment: dataInfo.monthlyPayment,
      loanType: dataInfo.loanType,
      lastPaidAmount: dataInfo.lastPaidAmount,
      remaining_balance: dataInfo.remaining_balance,
      due_date: dataInfo.due_date,
    };
    setLoanInfo(loanInfo);
  }, [dataInfo, amountLoan, termLoan, interest_rate]);

  const handlePaymentModal = (e) => {
    e.stopPropagation();
    e.preventDefault();
    const dataValue = {
      borrower_id: data.borrower_id,
      loanId: dataInfo.loanId,
      type: e.currentTarget.textContent,
      computed: loanInfo,
      btnValue: e.currentTarget.value,
    };
    paymentclick(dataValue);
  };
  const { isAdmin } = useAuth();

  let content = (
    <>
      <Accordion
        expanded={expanded}
        onChange={handleExpansion}
        slots={{ transition: Fade }}
        slotProps={{ transition: { timeout: 400 } }}
        sx={{
          "& .MuiAccordion-region": { height: expanded ? "auto" : 0 },
          "& .MuiAccordionDetails-root": {
            display: expanded ? "block" : "none",
          },
        }}
        style={{
          border: "2px solid " + loan_status,
          width: "100%",
          maxWidth: "1360px",
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
          xs={{ display: "flow" }}
        >
          <Stack
            direction={isMobile ? "column" : "row"}
            spacing={2}
            display={"flex"}
            width={"100%"}
            padding={2}
          >
            <Stack
              direction="column"
              spacing={1}
              style={{
                display: "flex",
                minWidth: "35%",
              }}
            >
              <Stack
                direction="row"
                spacing={2}
                display={"flex"}
                textAlign={"center"}
                alignContent={"center"}
                justifyContent={"center"}
              >
                <Typography fontSize={18} fontWeight={700}>
                  Loan Information{" "}
                </Typography>
              </Stack>
              <Stack direction="row" spacing={2}>
                <Typography fontSize={16} width={150}>
                  Loan Type:
                </Typography>
                {isLoading ? (
                  <Skeleton animation="wave" />
                ) : (
                  <Typography fontWeight={700}>
                    {dataInfo.loanType === 1
                      ? "Flexible"
                      : dataInfo.loanType === 2
                      ? "10th-25th"
                      : "Monthly"}
                  </Typography>
                )}
              </Stack>
              <Stack direction="row" spacing={2}>
                <Typography fontSize={16} width={150}>
                  Capital Amount:
                </Typography>
                {isLoading ? (
                  <Skeleton animation="wave" />
                ) : (
                  <Typography fontWeight={700}>
                    ₱
                    {dataInfo?.originalAmount?.toLocaleString("en-US", {
                      style: "decimal",
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </Typography>
                )}
              </Stack>
              {dataInfo.loanType === 1 && (
                <Stack direction="row" spacing={2}>
                  <Typography fontSize={16} width={150}>
                    Remaining Balance:
                  </Typography>
                  {isLoading ? (
                    <Skeleton animation="wave" />
                  ) : (
                    <Typography fontWeight={700}>
                      ₱
                      {dataInfo.loanAmount.toLocaleString("en-US", {
                        style: "decimal",
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                    </Typography>
                  )}
                </Stack>
              )}
              <Stack direction="row" spacing={2}>
                <Typography fontSize={16} width={150}>
                  Loan Interest:
                </Typography>
                {isLoading ? (
                  <Skeleton animation="wave" />
                ) : (
                  <Typography fontWeight={700}>
                    {dataInfo.loanInterest}%
                  </Typography>
                )}
              </Stack>
              <Stack
                direction="row"
                spacing={2}
                display={dataInfo.loanType === 2 ? "none" : "flex"}
              >
                <Typography fontSize={16} width={150}>
                  Terms:
                </Typography>
                {isLoading ? (
                  <Skeleton animation="wave" />
                ) : (
                  <Typography fontWeight={700}>
                    {dataInfo.loanType === 1
                      ? `${dataInfo.paidTerms} of ${dataInfo.loanTerm} Months`
                      : `${dataInfo.paidTerms} out of ${dataInfo.loanTerm}`}
                  </Typography>
                )}
              </Stack>
              <Stack direction="row" spacing={2}>
                <button
                  onClick={handlePaymentModal}
                  style={{
                    border: "none",
                    background: "none",
                    color: "blue",
                    cursor: "pointer",
                  }}
                  value={"View more.."}
                  className="BtnviewMore"
                >
                  View more..
                </button>
              </Stack>
            </Stack>
            <Stack direction="column" spacing={1}>
              <Stack
                direction="row"
                spacing={2}
                display={"flex"}
                textAlign={"center"}
                alignContent={"center"}
                justifyContent={"center"}
                style={{
                  display: "flex",
                  minWidth: "40%",
                }}
              >
                <Typography fontSize={18} fontWeight={700}>
                  Loan Amortization
                </Typography>
              </Stack>
              <Stack direction="row" spacing={2}>
                <Typography fontSize={16} width={150}>
                  Next Due Date:
                </Typography>
                {isLoading ? (
                  <Skeleton animation="wave" />
                ) : (
                  <Typography fontWeight={700}>{dataInfo.due_date}</Typography>
                )}
              </Stack>
              <Stack direction="row" spacing={2}>
                <Typography fontSize={16} width={150}>
                  Loan Interest:
                </Typography>
                {isLoading ? (
                  <Skeleton animation="wave" />
                ) : (
                  <Typography fontWeight={700}>
                    ₱
                    {dataInfo.monthlyPayment.toLocaleString("en-US", {
                      style: "decimal",
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </Typography>
                )}
              </Stack>
              <Stack direction="row" spacing={2}>
                <Typography fontSize={16} width={150}>
                  Total Loan Amount:
                </Typography>
                {isLoading ? (
                  <Skeleton animation="wave" />
                ) : (
                  <Typography fontWeight={700}>
                    ₱
                    {dataInfo.finalAmount.toLocaleString("en-US", {
                      style: "decimal",
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </Typography>
                )}
              </Stack>
            </Stack>
            {dataInfo.loan_status === 2 ? (
              <Stack
                direction="column"
                spacing={1}
                style={{
                  display: "flex",
                  minWidth: "30%",
                  textAlign: "right",
                  alignItems: "flex-end",
                  justifyContent: "center",
                }}
              >
                <Stack direction={"row"} spacing={2}>
                  {isLoading ? (
                    <Skeleton animation="wave" height={20} />
                  ) : (
                    <>
                      {parseFloat(dataInfo.remaining_balance) <= 0 ? (
                        <Typography fontSize={16} fontWeight={700}>
                          Fully Paid
                        </Typography>
                      ) : (
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={handlePaymentModal}
                          style={{ width: "150px" }}
                          value={"ADD PAYMENT FORM"}
                          data-id={dataInfo.loanId}
                        >
                          Add Payment
                        </Button>
                      )}
                    </>
                  )}
                </Stack>
                <Stack direction={"row"} spacing={2}>
                  {isLoading ? (
                    <Skeleton animation="wave" height={20} />
                  ) : (
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={handlePaymentModal}
                      style={{ width: "150px" }}
                      value={"RELOAN FORM"}
                    >
                      Reloan
                    </Button>
                  )}
                </Stack>
                <Stack direction={"row"} spacing={2}>
                  {isLoading ? (
                    <Skeleton animation="wave" height={20} />
                  ) : (
                    <>
                      <Button
                        variant="contained"
                        color="warning"
                        onClick={handlePaymentModal}
                        style={{ width: "150px" }}
                        value={"WITHDRAW FORM"}
                      >
                        Withdraw
                      </Button>
                    </>
                  )}
                </Stack>
              </Stack>
            ) : dataInfo.loan_status !== 3 ? (
              <>
                {isAdmin &&
                  (dataInfo.loan_status !== 1 ? (
                    <Stack
                      direction="column"
                      spacing={1}
                      style={{
                        display: "flex",
                        minWidth: "30%",
                        textAlign: "center",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Stack direction={"row"} spacing={2}>
                        {isLoading ? (
                          <Skeleton animation="wave" height={20} />
                        ) : (
                          <Button
                            variant="contained"
                            color="success"
                            onClick={handleDialogOpen}
                            style={{ width: "150px" }}
                            value={1}
                            data-id={dataInfo.loanId}
                            data-effective={dataInfo.effectived_date}
                          >
                            Approve
                          </Button>
                        )}
                      </Stack>
                      <Stack direction={"row"} spacing={2}>
                        {isLoading ? (
                          <Skeleton animation="wave" height={20} />
                        ) : (
                          <Button
                            variant="contained"
                            color="error"
                            onClick={handleDialogOpen}
                            style={{ width: "150px" }}
                            value={2}
                            data-id={dataInfo.loanId}
                            data-effective={dataInfo.effectived_date}
                          >
                            Reject
                          </Button>
                        )}
                      </Stack>
                    </Stack>
                  ) : (
                    <Typography
                      fontSize={16}
                      color={"red"}
                      fontWeight={700}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                      width={"30%"}
                    >
                      Status Rejected
                    </Typography>
                  ))}
              </>
            ) : (
              <Stack
                direction="column"
                spacing={1}
                style={{
                  display: "flex",
                  minWidth: "30%",
                  textAlign: "center",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Typography fontSize={16} color={"red"} fontWeight={700}>
                  {dataInfo.loan_status === 3
                    ? "Status Closed: Reloaned"
                    : "Fully Paid"}
                </Typography>
              </Stack>
            )}
          </Stack>
        </AccordionSummary>
        <AccordionDetails>
          {dataInfo.loanType === 0 ? (
            <>
              <VLineStepper
                term={termLoan}
                paidTerms={dataInfo.paidTerms}
                payments={dataInfo.payments}
                amount={amountLoan}
                type={interest_rate}
                effectived_date={dataInfo.effectived_date}
                loan_type={dataInfo.loanType}
                remaining_balance={dataInfo.remaining_balance}
              />
            </>
          ) : (
            <TransactionFlexibleTable
              paymentData={data}
              isLoading={isLoading}
            />
          )}
        </AccordionDetails>
      </Accordion>
    </>
  );

  if (type) {
    return <div style={{ width: "100%", marginTop: "10px" }}>{content}</div>;
  } else {
    if (data.status !== 1 && data.status !== 3) {
      return <div style={{ width: "100%", marginTop: "10px" }}>{content}</div>;
    }
  }
};

export default AccordionTransition;
