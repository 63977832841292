import { Box, Button, Grid } from "@mui/material";
import SignatureCanvas from "react-signature-canvas";
import React, { useRef } from "react";

export const Signature = ({ onClose, onConfirm }) => {
  const signatureRef = useRef(null);
  const handleClearSignature = () => {
    signatureRef.current.clear();
  };
  const handleSubmit = () => {
    const imgSrc = signatureRef.current.toDataURL();
    onConfirm(imgSrc);
    onClose();
  };
  return (
    <>
      <Grid item xs={12}>
        <Box
          border={1}
          borderRadius={0}
          textAlign="center"
          p={2}
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            bgcolor: "background.paper",
          }}
        >
          <SignatureCanvas
            ref={signatureRef}
            penColor="black"
            canvasProps={{
              width: window.innerWidth - 50,
              height: window.innerHeight - 50,
              className: "signatureCanvas",
            }}
          />
          <Button
            onClick={handleClearSignature}
            sx={{
              position: "absolute",
              bottom: 20,
              left: "50%",
              transform: "translateX(-50%)",
              zIndex: 99,
            }}
          >
            Clear Signature
          </Button>
        </Box>
      </Grid>

      <Grid
        item
        xs={12}
        textAlign={"right"}
        sx={{
          //position: "absolute",
          //bottom: 20,
          top: "10%",
          left: "50%",
          marginTop: "-40%",
          transform: "translateX(0%)",
          zIndex: 1,
        }}
      >
        <Button variant="contained" color="success" onClick={handleSubmit}>
          Confirm
        </Button>
        <Button
          variant="contained"
          color="error"
          onClick={() => onClose()}
          sx={{ marginLeft: 2 }}
        >
          Close
        </Button>
      </Grid>
    </>
  );
};
